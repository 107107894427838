<template>
  <div class="page">
    <div class="top">
      <!--  顶部-->
      <div class="reply" v-if="dataList.fowardText">
        <div class="content">
          <div class="title">
            <div class="tip">推荐转发文案:</div>
            <div class="copy" @click="copyText(dataList.fowardText)">复制</div>
          </div>
          <div class="text">{{ dataList.fowardText }}</div>
        </div>
      </div>
      <div class="tips">
        <div class="tip">
          <span class="text">长按保存专属海报分享给好友</span>
        </div>
        <div class="tip">
          <span class="text long-text">好友扫码添加微信后即助力成功</span>
        </div>
      </div>
      <div class="code-image">
        <div class="code-border">
          <!--  个人海报  -->
          <div v-show="posterType == 0">
            <div id="code-template" ref="template" v-show="!posterImage"></div>
            <img
              :src="posterImage"
              alt=""
              v-if="posterImage"
              :style="{ height: bgHeight, width: '100%' }"
            />
          </div>
          <!--          个人名片-->
          <div
            id="code-template-simple"
            ref="template_simple"
            v-if="posterType == 1"
          >
            <div class="info">
              <div class="left">
                <img class="logo" :src="dataList.cardCorpLogo" />
              </div>
              <div class="right">
                <div class="title">{{ dataList.cardCorpImageName }}</div>
                <div class="name">{{ dataList.cardCorpName }}</div>
              </div>
            </div>
            <div class="code">
              <img class="qrcode" :src="dataList.qrcodeUrl" />
              <img class="logo" :src="dataList.cardCorpLogo" />
            </div>
            <div class="tip">扫一扫上面的二维码图案</div>
            <div class="tip">加我企业微信</div>
          </div>
        </div>
      </div>
    </div>
    <div class="bottom-box">
      <div
        class="long-btn"
        @click="
          $router.push(
            '/workFission/speed?id=' +
              fissionId +
              '&unionId=' +
              wxUserData.unionid
          )
        "
      >
        查看助力进度
      </div>
    </div>
    <input type="text" ref="copyInput" style="position: fixed; opacity: 0" />

    <canvas ref="canvas" width="720" height="1280">
      您的浏览器不支持canvas
    </canvas>
  </div>
</template>

<script>
import { posterApi, openUserInfoApi } from "@/api/workFission";
import {wxConfig} from "../../utils/wxCodeAuth";

export default {
  name: "index",
  data() {
    return {
      url: window.location.href,
      //海报图片
      posterImage: "",
      dataList: {},
      userInfo: {},
      bgHeight: "auto",
      base64Img: "",
      //  微信用户数据
      wxUserData: {},
      //  页面类型
      posterType: 0,
      fissionId: 0,
      wxUserId:'',
    };
  },
  created() {
    this.fissionId = this.$route.query.id;
    this.wxUserId = this.$route.query.wxUserId;
    console.log('wxUserId' + this.wxUserId)
    this.getOpenUserInfo();
  },
  methods: {
    getOpenUserInfo() {
      let that = this;
      openUserInfoApi({
        id: that.fissionId,
      }).then((res) => {
        if (res.data.openid === undefined) {
          let redirectUrl = location.protocol + "//" + location.host +
            "/auth/workFission?id=" +
            that.fissionId +
            "&target=" + encodeURIComponent("/workFission" + location.search);
          window.location.href = redirectUrl
          return
        }
        this.wxUserData = res.data;
        this.getPosterData();
      });
    },
    //获取页面海报信息
    getPosterData() {
      let params = {
        fissionId: this.fissionId,
        wxUserId: this.wxUserId,
        unionId: this.wxUserData.unionid,
        nickname: this.wxUserData.nickname,
        avatar: this.wxUserData.headimgurl,
      };
      posterApi(params).then((res) => {
        this.posterType = res.data.posterType;
        this.dataList = res.data;

        //分享内容
        this.shareOption = {
          title: res.data.linkTitle, // 分享标题
          desc: res.data.linkDesc, // 分享描述
          link: this.url, // 分享链接，该链接域名或路径必须与当前页面对应的公众号 JS 安全域名一致
          imgUrl: res.data.linkPic, // 分享图标
          success: function () {
            // 用户点击了分享后执行的回调函数
          }
        }

        let that = this;
        let shareFunction = function () {   //需在用户可能点击分享按钮前就先调用
          console.log("分享内容2222", that.shareOption)
          wx.updateAppMessageShareData(that.shareOption);
          wx.updateTimelineShareData(that.shareOption);
        }

        //加载微信配置
        wxConfig(shareFunction);

        this.createPoster();
      });
    },
    async createPoster() {
      const ctx = this.$refs.canvas.getContext("2d");

      const w = 720;
      const h = 1280;

      const bg = await this.getImg(this.dataList.coverPic);
      const qr = await this.getImg(this.dataList.qrcodeUrl);

      ctx.drawImage(bg, 0, 0, w, h);
      //ctx.drawImage(qr, 550, 1100, 150, 150);
      ctx.drawImage(qr, 500, 1050, 200, 200);

      if (this.wxUserData.nickname) {
        ctx.font = "30px Verdana";
        ctx.fillStyle = this.dataList.nicknameColor
          ? this.dataList.nicknameColor
          : "#fff";
        ctx.fillText(this.wxUserData.nickname, 115, 74);
      }

      if (this.wxUserData.headimgurl) {
        const avatar = await this.getImg(this.wxUserData.headimgurl);
        ctx.drawImage(avatar, 30, 30, 70, 70);
      }

      this.posterImage = this.$refs.canvas.toDataURL("image/png");
    },
    copyText(text) {
      const inputElement = this.$refs.copyInput;
      inputElement.value = text;
      inputElement.select();
      document.execCommand("Copy");
      this.$message.success("复制成功");
    },
    getImg(url) {
      url += "?rand=" + Math.random();

      return new Promise((resolve) => {
        const img = new Image();

        img.src = url;
        img.crossOrigin = "Anonymous";

        img.onload = () => {
          resolve(img);
        };

        img.onerror = (err) => {
          console.log("图片加载失败");
        };
      });
    },
  },
};
</script>

<style scoped lang="less">
canvas {
  position: absolute;
  top: 0;
  left: 0;
  width: 720px;
  height: 1280px;
  z-index: 10000;
  display: none;
}

.page {
  width: 100vw;
  height: 100vh;
  background-color: #ff5636;
  padding: 0 20px 80px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-image: url("../../static/images/bg.png");
  background-size: cover;
  overflow-y: auto;

  .top {
    .reply {
      padding-top: 16px;

      .content {
        padding: 16px 20px;
        border-radius: 16px;
        background-color: rgb(253, 253, 229);

        .title {
          display: flex;
          justify-content: space-between;

          .tip {
            color: saddlebrown;
            font-size: 15px;
            position: relative;

            &:before {
              content: "";
              position: absolute;
              bottom: 0;
              left: 0;
              width: 100%;
              height: 7px;
              background-color: #b97e1229;
            }
          }

          .copy {
          }
        }

        .text {
        }
      }
    }

    .tips {
      padding: 20px 0;

      .tip {
        display: flex;
        justify-content: center;
        margin-bottom: 4px;

        .text {
          position: relative;
          color: white;
          z-index: 0;

          &::before {
            position: absolute;
            content: "";
            bottom: 0;
            z-index: -1;

            left: ((100% - 90%) / 2);

            height: 10px;
            width: 90%;
            border-radius: 100px;
            background-color: rgba(255, 255, 255, 0.15);
          }
        }

        .long-text {
          &::before {
            left: ((100% - 110%) / 2);
            width: 110%;
          }
        }
      }
    }

    .code-image {
      width: 100%;
      background-color: navajowhite;
      border-radius: 10px;
      box-shadow: inset 0 4px 6px 0 #0000001c;
      border: 2px solid white;
      padding: 10px;

      .code-border {
        width: 100%;
        padding: 16px;
        background-color: white;
        border-radius: 12px;

        #code-template {
          position: relative;

          .bg {
            width: 100%;
          }

          .user-info {
            position: absolute;
            top: 10px;
            left: 10px;
            display: flex;
            align-items: center;

            .avatar {
              margin-right: 10px;
              width: 36px;
              height: 36px;
              border-radius: 50%;
            }

            .name {
              font-size: 14px;
            }
          }

          .qrcode {
            width: 150px;
            height: 150px;
            position: absolute;
            bottom: 0;
            right: 0;
          }
        }

        #code-template-simple {
          border: 1px solid gray;
          padding: 20px;

          .info {
            display: flex;
            align-items: center;
            margin-bottom: 16px;

            .left {
              margin-right: 10px;

              .logo {
                width: 34px;
                height: 34px;
              }
            }

            .right {
              .title {
                font-size: 14px;
                color: #000000;
              }

              .name {
                font-size: 12px;
              }
            }
          }

          .code {
            width: 84%;
            display: flex;
            justify-content: center;
            align-items: center;
            position: relative;
            margin: 0 auto 20px;

            .qrcode {
              width: 100%;
              height: 100%;
            }

            .logo {
              border: 1px solid white;
              position: absolute;
              width: 24%;
              height: 24%;
            }
          }

          .tip {
            text-align: center;
          }
        }
      }
    }
  }

  .bottom-box {
    width: 100vw;
    position: fixed;
    bottom: 0;
    left: 0;
    padding: 10px 0 15px;
    box-shadow: 0 -2px 6px 0 rgba(0, 0, 0, 0.19);
    background-color: #ff5636;
    display: flex;
    justify-content: center;

    .long-btn {
      width: 90%;
      height: 42px;
      display: flex;
      justify-content: center;
      align-items: center;
      color: darkorange;
      font-size: 16px;
      border: 2px solid #ffd6a1;
      border-radius: 100px;
      background-color: #ffecdb;
    }
  }
}

.user_img {
  width: 35px;
  height: 35px;
  border-radius: 5px;
}
</style>
